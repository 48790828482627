<template>
    <LiefengContent>
        <template #title>{{ menuCodeObj.menuName || "应有已有户数统计" }}</template>
        <template #toolsbarRight>
            <Button :disabled="loading" style="margin: 0" :type="!orgOnlineStatus ? 'primary' : ''" @click="checkStatus('')">全部</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 1 ? 'primary' : ''" @click="checkStatus(1)">市级示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 2 ? 'primary' : ''" @click="checkStatus(2)">区级试用社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 3 ? 'primary' : ''" @click="checkStatus(3)">街镇试用社区</Button>

            <Button :disabled="loading" :type="level == 3 ? 'info' : ''" @click="changeLevel(3)" style="margin: 0 0 0 50px">按区</Button>
            <Button :disabled="loading" :type="level == 4 ? 'info' : ''" @click="changeLevel(4)" style="margin: 0">按街道</Button>
            <Button :disabled="loading" :type="level == 5 ? 'info' : ''" @click="changeLevel(5)" style="margin: 0">按社区</Button>

            <Button :disabled="loading" type="error" style="margin: 0 0 0 50px" @click="Export">导出报表</Button>
        </template>
        <template #contentArea>
            <Form :label-colon="true" :inline="true" class="search" style="position: relative; margin: 5px 0; display: flex; justify-content: space-between">
                <div style="display: flex; width: 100%">
                    <LiefengCascaderCity
                        v-if="showCascader"
                        :disabled="disabled"
                        :orgLevel="level"
                        @changeCasader="changeCasader"
                        :resetNum="resetNum"
                        :orgCode="4401"
                        :width="200"
                        :isClear="true"
                    ></LiefengCascaderCity>

                    <div style="display: flex; justify-content: space-between; margin-left: auto">
                        <span style="margin-top: 7px; margin-left: 10px">更新时间：{{ nowTime1 + " 00:00:00" || "" }}</span>
                    </div>
                </div>
            </Form>

            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="isHidePage"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[50, 1000, 2000, 3000]"
                @hadlePageSize="hadlePageSize"
                :showIndex="false"
            ></LiefengTable>

            <!-- 社区空白栏目 -->
            <LiefengModal :title="nameUrl" width="700px" height="70vh" :value="blankStatus" @input="changeBlankStatus">
                <template v-slot:title>
                    <p style="font-size: 18px; font-weight: 700">空白栏目数：{{ clumnNum }}</p>
                    <p>更新时间：{{ nowTime2 + " 00:00:00" || "" }}</p>
                </template>
                <template v-slot:contentarea>
                    <LiefengTable :tableData="bTableData" :talbeColumns="bTalbeColumns" :loading="bLoading" :hidePage="true"></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="blankStatus = false">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "./children/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            orgOnlineStatus: 1, // 切换的状态
            level: 5, // 切换的级别
            columnName: 0, // 栏目数
            nowTime1: "", // 当前时间
            resetNum: 1, // 是否清空当前社区选择

            tableData: [],
            talbeColumns: [],
            loading: false,
            menuCodeObj: {},

            cascaderList: [],

            // 栏目点击参数

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "序号",
                    key: "num",
                    align: "center",
                    width: 80,
                },
                {
                    title: "栏目路径",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                    minWidth: 80,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",

            onlineType: "",

            disabled: false, // 是否禁用选择器
            isHidePage: false,

            page: 1,
            pageSize: 50,
            total: 0,

            allTableData: [], //暂存全部信息
            showCascader: true,
        }
    },
    async created() {
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        await this.getByMenuCode()
        await this.getList()
    },
    methods: {
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.tableData = []
            if(val.page == 1){
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice(0, this.page * this.pageSize)
            }else{
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice((this.page * this.pageSize) - (this.pageSize), this.page * this.pageSize)
            }
        },
        // 改变示范社区东西
        changeSelect(val) {
            if (val == undefined) return
            this.getList()
        },
        // 改变选择社区组件
        changeCasader(val) {
            this.cascaderList = val
            this.onlineType = ""
            if (!val.length) {
                this.resetNum++
                this.getList()
            }
            if (this.level == "3") {
                if (val.length == 1) this.getList()
            } else if (this.level == "4") {
                if (val.length == 2) this.getList()
            } else if (this.level == "5") {
                if (val.length == 3) this.getList()
            }
        },
        async checkStatus(val) {
            if (this.orgOnlineStatus == val) return
            this.orgOnlineStatus = val
            await this.getList()
        },
        async changeLevel(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            this.cascaderList = []
            await this.getList()
        },

        async changeLevel2(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            await this.getList()
        },

        //获取列表
        async getList(val) {
            let arr1 = []
            let arr2 = []
            this.disabled = true
            if (this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 200,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 200,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 200,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
                arr2 = [
                    {
                        title: "总户数(户)",
                        key: "houseNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "空置房(户/占比)",
                        key: "emptyHouseNumScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已入驻居民(户/占比)",
                        key: "houseScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "未入驻居民(户/占比)",
                        key: "houseNotScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已有户代表(户/占比)",
                        key: "houseBehalfScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "没有户代表(户/占比)",
                        key: "houseNotBehalfScale2",
                        align: "center",
                        minWidth: 100,
                    },
                ]
            // } else if (this.orgOnlineStatus == 2 && this.level != 5) {
                // if (this.level == 3 || this.level == 4) {
                //     arr1 = [
                //         {
                //             title: "区",
                //             key: "zoneName",
                //             align: "center",
                //             width: 200,
                //             render: (h, params) => {
                //                 return h(
                //                     "div",
                //                     {
                //                         style: {
                //                             color: this.level == 3 ? "blue" : "#000",
                //                             cursor: this.level == 3 ? "pointer" : "auto",
                //                             textDecoration: this.level == 3 ? "underline" : "none",
                //                         },
                //                         on: {
                //                             click: async () => {
                //                                 if (this.level == 3) {
                //                                     this.cascaderList = [params.row.zoneCode]
                //                                     this.changeLevel2(4)
                //                                 }
                //                             },
                //                         },
                //                     },
                //                     params.row.zoneName
                //                 )
                //             },
                //         },
                //     ]
                //     arr2 = [
                //     {
                //         title: "总户数(户)",
                //         key: "houseNum",
                //         align: "center",
                //         minWidth: 100,
                //     },
                //     {
                //         title: "空置房(户/占比)",
                //         key: "emptyHouseNumScale2",
                //         align: "center",
                //         minWidth: 100,
                //     },
                //     {
                //         title: "已入驻居民(户/占比)",
                //         key: "houseScale2",
                //         align: "center",
                //         minWidth: 100,
                //     },
                //     {
                //         title: "未入驻居民(户/占比)",
                //         key: "houseNotScale2",
                //         align: "center",
                //         minWidth: 100,
                //     },
                //     {
                //         title: "已有户代表(户/占比)",
                //         key: "houseBehalfScale2",
                //         align: "center",
                //         minWidth: 100,
                //     },
                //     {
                //         title: "没有户代表(户/占比)",
                //         key: "houseNotBehalfScale2",
                //         align: "center",
                //         minWidth: 100,
                //     },
                // ]
                // }
            } else if (this.level == 5) {
                arr1 = [
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "社区",
                        key: "communityName",
                        align: "center",
                        width: 200,
                    },
                ]
                arr2 = [
                    {
                        title: "总户数(户)",
                        key: "houseNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "空置房(户/占比)",
                        key: "emptyHouseNumScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已入驻居民(户/占比)",
                        key: "houseScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "未入驻居民(户/占比)",
                        key: "houseNotScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已有户代表(户/占比)",
                        key: "houseBehalfScale2",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "没有户代表(户/占比)",
                        key: "houseNotBehalfScale2",
                        align: "center",
                        minWidth: 100,
                    },
                ]
            }
            this.talbeColumns = [...arr1, ...arr2]
            this.disabled = false
            this.loading = true
            let res = await this.$get("/gateway/api/symanage/pc/uaBase/selectView", {
                orgCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[this.cascaderList.length - 1] : "",
                onlineType: this.orgOnlineStatus,
                orgLevel: this.level,
            })
            if (res.code == 200) {
                this.loading = false
                this.tableData = res.dataList.map(item =>{
                    return{
                        ...item,
                        emptyHouseNumScale2: item.emptyHouseNum + ' | ' + item.emptyHouseNumScale,
                        houseScale2:item.houseFillNum + ' | ' + item.houseScale,
                        houseNotScale2:(item.houseNum - item.emptyHouseNum - item.houseFillNum) < 0 ? 0 + ' | ' + item.houseNotScale : (item.houseNum - item.emptyHouseNum - item.houseFillNum) + ' | ' + item.houseNotScale,
                        houseBehalfScale2:item.behalfHouseNum + ' | ' + item.houseBehalfScale,
                        houseNotBehalfScale2:(item.houseNum - item.emptyHouseNum - item.behalfHouseNum) < 0 ? 0 + ' | ' + item.houseNotBehalfScale : (item.houseNum - item.emptyHouseNum - item.behalfHouseNum) + ' | ' + item.houseNotBehalfScale

                    }
                })
                this.columnName = 0
                this.allTableData = this.tableData
                if (this.level == 5) {
                    this.total = this.tableData.length
                    this.tableData = JSON.parse(JSON.stringify(this.allTableData)).splice(0, 50)
                }
            } else {
                this.loading = false
                this.$Message.error({
                    content: res.decs,
                    background: true,
                })
            }
        },
        // 获取columnCode
        async getByMenuCode() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        },
        //导出报表
        Export() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "应有已有户数统计结果",
                onOk: () => {
                    // if (this.level == 5) {
                    //     let tHeader = ["区", "街道", "社区", "十五分钟生活圈总数", "未建立十五分钟生活圈总数", "是否建立" , "占比"]
                    //     let filterVal = ["zoneName", "streetName", "communityName", "totalNum", "nullNum", "submit", "scale"]
                    //     let tableData = JSON.parse(JSON.stringify(this.allTableData))
                    //     this.$core.exportExcel(tHeader, filterVal, tableData, "空白栏目统计结果")
                    // } else {
                    this.$refs.tab.$refs.lftable.exportCsv({
                        filename: "应有已有户数统计结果",
                    })
                    // }
                },
            })
        },

        // 获取空白栏目列表
        getBlankList(orgCode) {
            this.bLoading = true
            this.$get("/statistic/api/symanage/pc/columnTree/selectLayerNulByOrgCode", {
                orgCode,
            })
                .then(res => {
                    this.bLoading = false
                    if (res.code == 200 && res.dataList) {
                        this.nowTime2 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                        this.bTableData = res.dataList.map((item, index) => {
                            return {
                                num: index + 1,
                                ...item,
                            }
                        })
                        this.clumnNum = this.bTableData.length
                    } else {
                        this.nowTime2 = ""
                        this.$Message.error({
                            content: res.decs,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.nowTime2 = ""
                    this.bLoading = false
                    this.$Message.error({
                        content: "获取空白栏目失败",
                        background: true,
                    })
                })
        },
        changeBlankStatus(val) {
            this.blankStatus = val
        },
    },
}
</script>
    
<style scoped lang='less'>
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.ivu-form-item {
    margin-bottom: 5px;
    margin-right: 0px;
}
/deep/.ivu-table-sort {
    width: 26px;
    height: 13px;
    i {
        font-size: 27px;
    }
}
</style>